<template>
  <div class='home'>
     <!-- 选择 -->
     <single-choice  :value="value" v-if='this.type==0||this.type==3||this.type==7'  ref="single" @fromChild="getChild"></single-choice>
     <!-- 阅读理解、完形填空 -->
     <reading-comprehension  :value="value" v-if='this.type==4||this.type==6'   ref="reading" @fromChild="getChild"></reading-comprehension>
     <!-- 写作 -->
     <writing    :value="value" v-if='this.type==2||this.type==5'   ref="writing" @fromChild="getChild"></writing>
  </div>
</template>

<script>
import request from "../plugin/request";
import SingleChoice from '@/components/SingleChoice'
import ReadingComprehension from '@/components/ReadingComprehension'
import writing from '@/components/writing'
import Cookies from "js-cookie";
import Writing from '../components/writing.vue';
export default {
  components: {
    writing,
    SingleChoice,
    ReadingComprehension,
    Writing
  },
  data() {
    return {
      type:'', 
      token:'',
      value:{},
      index:0,
      paper_id:''
    }
  },
  mounted() {
    this.paper_id=this.$route.query.paper_id
    this.token = this.$route.query.token;
    this.$nextTick(() => {
      this.setToken();
      this.getQuestion()
    });
  },
  methods:{
     getChild(v) {
      this.index = v.index;
      this.TestPaper();
    },
    setToken() {
      return Cookies.set('Token', this.token)
    },
    // 获取题目
   async getQuestion() {
      const {data}=await request.post('/app/exam/makeQuestions',{
        paper_id:this.paper_id
      })
      let isSameCom = false;
      if (this.type == data.question[Number(this.index)].type) {
        isSameCom = true;
      }
      console.log(data.question[Number(this.index)].type)
     this.type=data.question[Number(this.index)].type
      if(data.question[Number(this.index)].type==0 ||data.question[Number(this.index)].type==3 || this.type == 7) {
        this.value=data
        this.value.analysis=1
        this.value.everyday=1
      } else if(this.type==4 ||this.type==6) {
        this.value=data.question[Number(this.index)]
        this.value.record_id=data.record_id
        this.value.analysis=1
        this.value.everyday=1
      } else if(this.type == 2 || this.type == 5) {
        this.value=data
        this.value.analysis=1
        this.value.everyday=1
      }
      console.log(this.type)
      if (isSameCom && (this.type == 0 || this.type == 3|| this.type == 7) ) {
        this.$nextTick(() => {
          this.$refs.single.refreshData();
        });
      }
      if (isSameCom && (this.type == 4 || this.type == 6) ) {
        this.$nextTick(() => {
          this.$refs.reading.refreshData();
        });
      }
      
      if (isSameCom && (this.type == 2 || this.type == 5) ) {
        this.$nextTick(() => {
          this.$refs.writing.refreshData();
        });
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.home {
 width: 100%;
    touch-action: pan-y!important;

.box {
  width: 100%;
  height: 1.84rem;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 1000;

    .top {
    width: 100%;
    height: 1.71rem;
    background-color: #fff;
    padding:0  .53rem;
    box-sizing: border-box;
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content: space-between;
    // margin-bottom:.13rem;
    
    div {
      width:33%;

      .img {
        width: 100%;
        height: 1.23rem;
        position: relative;

        img {
          position: absolute;
          top:50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      
      }

      p {
        width: 100%;
        text-align: center;
        font-size: .24rem;
        color: #585A5B;
      }
    }
    .time {
      img {
        width: .53rem;
        height: .55rem;
      }
    }
    .Title_number {
      img {
        width: .56rem;
        height: .45rem;
      }
    }

    .Answer_sheet {
      img {
        width: .52rem;
        height: .55rem;
      }
    }

    .Collection {
      img {
        width: .55rem;
        height: .53rem;
      }
    }


  }
  .div {
    width: 100%;
    height: .13rem;
    background-color: #ebeff2;
    padding:0 ;
    margin:0
  }
}


}

</style>